import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"
import ButtonLink from "../components/ButtonLink"

const RadioPage = function ({ data: { radioJson }, location }) {
  return (
    <Layout>
      <Seo
        title={radioJson.title}
        description={radioJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={radioJson.banner.background}
        content={radioJson.banner.content}
      />
      <div className="container">
        <section>
          {parse(radioJson.intro)}

          <div className="md:flex md:flex-row">
            <div className="md:basis-1/2">{parse(radioJson.content)}</div>
            <div className="md:basis-1/2">
              <GatsbyImage
                image={radioJson.contentImage.childImageSharp.gatsbyImageData}
                alt="Illustration"
              />
            </div>
          </div>

          <div className="md:flex md:flex-row">
            <div className="md:basis-1/2">
              <GatsbyImage
                image={radioJson.content2Image.childImageSharp.gatsbyImageData}
                alt="Illustration"
              />
            </div>
            <div className="md:basis-1/2">{parse(radioJson.content2)}</div>
          </div>

          {parse(radioJson.content3)}
          <ul className="grid grid-cols-5 gap-2">
            {radioJson.chronology.map(function (item) {
              return (
                <li key={uuidv4()}>
                  <div>{item.date}</div>
                  <h5>{item.title}</h5>
                  <GatsbyImage
                    image={item.image.childImageSharp.gatsbyImageData}
                    alt="Illustration"
                  />
                </li>
              )
            })}
          </ul>

          <div className="md:flex md:flex-row">
            <div className="md:basis-1/2">{parse(radioJson.content4)}</div>
            <div className="md:basis-1/2">
              <GatsbyImage
                image={radioJson.content4Image.childImageSharp.gatsbyImageData}
                alt="Illustration"
              />
            </div>
          </div>

          {parse(radioJson.content5)}
          <ul className="grid md:grid-cols-2 gap-5">
            {radioJson.cotes.map(function (item) {
              return (
                <li key={uuidv4()}>
                  <GatsbyImage
                    image={item.icon.childImageSharp.gatsbyImageData}
                    alt="Icone"
                  />
                  <h3>{item.title}</h3>
                  {parse(item.content)}
                  <div>
                    <ButtonLink item={item.button} />
                  </div>
                </li>
              )
            })}
          </ul>
          {parse(radioJson.whyListen.title)}
          <ul className="grid grid-cols-5 gap-2">
            {radioJson.whyListen.items.map(function (item) {
              return (
                <li key={uuidv4()}>
                  <GatsbyImage
                    image={item.image.childImageSharp.gatsbyImageData}
                    alt="Illustration"
                  />
                  <h5>{item.title}</h5>
                </li>
              )
            })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

RadioPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default RadioPage

export const query = graphql`
  query RadioPageQuery {
    radioJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      intro
      content
      contentImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      content2Image {
        childImageSharp {
          gatsbyImageData
        }
      }
      content2
      content3
      chronology {
        date
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      content4
      content4Image {
        childImageSharp {
          gatsbyImageData
        }
      }
      content5
      cotes {
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        content
        button {
          label
          link
        }
      }
      whyListen {
        title
        items {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
        }
      }
    }
  }
`
